import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import SignaturePad from 'signature_pad';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { EventRetrieve } from '../../models/mip/event-retrieve.model';
import { MipDetailsService } from '../../services/mip/mip-details.service';
import { SignatureService } from '../../services/signature/signature.service';

@Component({
  selector: 'app-signature',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    InputTextareaModule,
  ],
  providers: [MessageService],
  templateUrl: './signature.component.html',
  styleUrl: './signature.component.scss'
})
export class SignatureComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _mipDetailsService = inject(MipDetailsService);
  private _signatureService = inject(SignatureService);

  public eventId: string | null = null;
  public event: EventRetrieve | null = null;
  public signatureSaved: boolean = false;

  @ViewChild('digital_sign', { static: false }) digital_sign!: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper!: ElementRef;

  Form: FormGroup;
  CanvasWidth = 500;
  CanvasHeight = 250;
  signaturePad!: SignaturePad;

  constructor(private FormBuilder: FormBuilder, private _messageService: MessageService) {
    this.Form = this.FormBuilder.group({
      signature: [''],
      name: ['', [Validators.required]],
      job_position: ['', [Validators.required]],
      comments: ['']
    });
  }

  ngOnInit(): void {
    this.eventId = this._route.snapshot.paramMap.get('id');

    if (this.eventId) {
      this.fetchEventData(this.eventId);
    }
  }

  fetchEventData(eventId: string): void {
    this._mipDetailsService.getEvent(eventId).subscribe((event: { data: EventRetrieve }) => {
      this.event = event.data;
      console.log('EL EVENTO: ', event.data);
    });
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.digital_sign.nativeElement);
    this.CanvasWidth = this.wrapper.nativeElement.offsetWidth;
  }

  onResize() {
    this.CanvasWidth = this.wrapper.nativeElement.offsetWidth;
  }

  accept() {
    const sign = this.signaturePad.toDataURL();
    var file = this.dataURLtoFile(sign, 'firma.jpg');
    this.Form.get('signature')?.setValue(file);

    if (this.Form.valid) {
      const data = {
        event: this.eventId,
        signature: this.Form.get('signature')?.value,
        name: this.Form.get('name')?.value,
        job_position: this.Form.get('job_position')?.value,
        comments: this.Form.get('comments')?.value
      };

      this._signatureService.saveSignature(data).subscribe({
        next: (signature: any) => {
          this.signatureSaved = true;
          this.cancel();
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error al guardar la firma',
            detail: 'Ha ocurrido un error al guardar la firma, por favor intente nuevamente.'
          });
        }
      });
    }
  }

  cancel() {
    this.Form.reset();
    this.signaturePad.clear();
  }

  clear() {
    this.signaturePad.clear();
    this.Form.get('signature')?.setValue('');
  }

  dataURLtoFile(dataUrl: any, filename: string) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
