import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { Menu, MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { environment } from '../../../../environments/environment.prod';
import { StatusFilterComponent } from '../../../shared/components/status-filter/status-filter.component';
import { STATUS_COLORS } from '../../../shared/constants/colors';
import type { Event as EventType, Service } from '../../models/events.model';
import { DocumentsService } from '../../services/documents/documents.service';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-services-table',
  standalone: true,
  imports: [
    ButtonModule,
    CalendarModule,
    DropdownModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    MultiSelectModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
    CommonModule,
    PaginatorModule,
    MenuModule,
    StatusFilterComponent
  ],
  providers: [MessageService],
  templateUrl: './services-table.component.html',
  styleUrl: './services-table.component.scss',
})
export class ServicesTableComponent implements OnInit, OnDestroy {
  @ViewChild('menuWithItems') menu!: Menu;

  private _eventsService = inject(EventsService);
  private _documentsService = inject(DocumentsService);
  private _router = inject(Router);

  public services: Service[] = [];
  public selectedService: Service | null = null;
  public statuses!: any[];
  public loading: boolean = false;

  public dataForm!: FormGroup;
  private filterSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  public menuItems: any[] = [
    {
      label: 'Ver servicio',
      icon: 'pi pi-eye',
      command: () => {
        if (this.selectedService) {
          this.handleSeeDetails(this.selectedService);
        }
      },
    },
    {
      label: 'Orden de servicio',
      icon: 'pi pi-folder-open',
      command: () => {
        if (this.selectedService) {
          this.handleServiceOrderDocument(this.selectedService);
        }
      }
    },
    {
      label: 'Certificado de servicio',
      icon: 'pi pi-folder-open',
      command: () => {
        if (this.selectedService) {
          this.handleServiceOrderCertificate(this.selectedService);
        }
      }
    }
  ];

  public menuNoItems: any[] = [
    {
      label: 'No hay opciones disponibles',
      disabled: true
    }
  ];

  constructor(
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.loadDataForm();
    this.loadServices();

    this.statuses = Object.keys(STATUS_COLORS).map((status) => ({
      label: status,
      value: status,
    }));

    this.filterSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.loadServices();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterInput(event: any, filter: string) {
    const inputElement = event.target as HTMLInputElement;
    this.dataForm.value[filter] = inputElement.value;

    this.filterSubject.next(inputElement.value);
  }

  onSearchDate(event: any) {
    const date = new Date(event);
    const formattedDate = date.toISOString().split('T')[0];
    this.dataForm.value.initial_date = formattedDate;

    this.loadServices();
  }

  onSelectStatus(event: any) {
    this.dataForm.value.status__name = event.name;

    this.loadServices();
  }

  resetFilters() {
    this.loadDataForm();
    this.loadServices();
  }

  loadDataForm(): void {
    this.dataForm = this._formBuilder.group({
      totalRecords: 0,
      page: 1,
      page_size: 10,
      firstPage: 0,
      search: '',
      initial_date: '',
      status__name: ''
    });
  }

  loadServices() {
    this.loading = true;

    this._eventsService.getEvents(this.dataForm.value).subscribe({
      next: (data: {
        items: EventType[];
        total: number;
        page: number;
        page_size: number;
      }) => {
        this.services = data.items.map((service) => ({
          ...service,
          employee: service.employee[0],
        }));

        this.dataForm.value.totalRecords = data.total;
        this.dataForm.value.page = data.page;
        this.dataForm.value.page_size = data.page_size;

        this.loading = false;
      },
      error: (err) => {
        console.error('Error al cargar servicios:', err);
        this.loading = false;
      },
    });
  }

  toggleMenu(event: Event, service: Service) {
    this.selectedService = service;
    this.menu.toggle(event);
  }

  handleSeeDetails(service: Service) {
    this._router.navigate(['/event-details', service.id]);
  }

  handleServiceOrderDocument(service: Service) {
    this.loading = true;

    this._documentsService.getServiceOrderPdf(service.id).subscribe({
      next: (response) => {
        const url = environment.documentStorageUrl + response.data;
        window.open(url, '_blank');
      },
      error: (err) => {
        console.error('Error fetching service order: ', err);
        this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener la orden de servicio' });

        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  handleServiceOrderCertificate(service: Service) {
    this.loading = true;

    this._documentsService.getServiceCertificatePdf(service.id).subscribe({
      next: (response) => {
        const url = environment.documentStorageUrl + response.data;
        window.open(url, '_blank');
      },
      error: (err) => {
        console.error('Error fetching service certificate: ', err);
        this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el certificado del servicio' });

        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  getSeverity(status: string) {
    switch (status) {
      case 'Cancelado': return 'danger';
      case 'Finalizado': return 'success';
      case 'Completado': return 'success';
      case 'Creado': return 'info';
      case 'Confirmado': return 'info';
      case 'Pendiente': return 'warning';
      case 'Programado': return 'info';
      case 'Comenzado': return 'warning';
      case 'En progreso': return 'warning';
      case 'Sincronizado': return 'success';
      default:
        return 'info';
    }
  }

  formatTime(time: string): string {
    const timeParts = time.split(':');
    return `${timeParts[0]}:${timeParts[1]}`;
  }

  pageChange(event: any) {
    const newPage = event.first / event.rows;

    this.dataForm.value.firstPage = event.first;
    this.dataForm.value.page = newPage + 1;

    this.loadServices();
  }
}
