import { Component } from '@angular/core';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { CalendarComponent } from '../../components/calendar/calendar.component';
import { ServicesTableComponent } from '../../components/services-table/services-table.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LayoutComponent,
    CalendarComponent,
    ServicesTableComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent { }
