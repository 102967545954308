<div class="home-element">
  <h2>
    Mis Servicios Programados
  </h2>

  <p-table
    #dt2
    [value]="services"
    dataKey="id"
    [rows]="rows"
    [rowsPerPageOptions]="[10, 25, 50]"
    [totalRecords]="totalRecords"
    [lazy]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} servicios"
    [loading]="loading"
    [paginator]="true"
    styleClass="p-datatable-striped"
    [globalFilterFields]="['folio', 'title', 'initial_date', 'status.name', 'employee.name']"
    [tableStyle]="{ 'min-width': '80rem' }"
    (onPage)="pageChange($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <p-iconField iconPosition="left" class="ml-auto">
          <p-inputIcon>
            <i class="pi pi-search"></i>
          </p-inputIcon>

          <input
            pInputText
            type="text"
            (input)="filterServices($event)"
            placeholder="Buscar"
          />
        </p-iconField>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width:15%">Folio</th>
        <th style="width:20%">Nombre</th>
        <th style="width:15%">Fecha y hora de servicio</th>
        <th style="width:15%">Estado</th>
        <th style="width:20%">Empleado</th>
        <th style="width:15%">Opciones</th>
      </tr>

      <tr>
        <th>
          <p-columnFilter
            type="text"
            field="folio"
            placeholder="Folio..."
            ariaLabel="Folio..."
          />
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="title"
            placeholder="Nombre..."
            ariaLabel="Nombre..."
          />
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="initial_date"
            placeholder="Fecha y hora..."
            ariaLabel="Fecha y hora..."
          />
        </th>

        <th>
          <p-columnFilter field="status.name" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown
                [options]="statuses"
                (onChange)="filter($event.value)"
                placeholder="Estado..."
                [showClear]="true"
              >
                <ng-template let-option pTemplate="item">
                  <span class="state-tag">
                    {{ option.value }}
                  </span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="employee.name"
            placeholder="Empleado..."
            ariaLabel="Empleado..."
          />
        </th>

        <th>
          <!--  -->
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-service>
      <tr>
        <td>{{ service.folio }}</td>
        <td>{{ service.title }}</td>

        <td class="date-in-table">
          {{ service.initial_date | date: 'dd/MM/yyyy' }}
          <br />
          {{ formatTime(service.initial_hour) }}
        </td>

        <td>
          <p-tag
            [value]="service.status.name"
            [severity]="getSeverity(service.status.name)"
          />
        </td>

        <td>{{ service.employee.name }}</td>

        <td class="options">
          <div>
            <p-button
              label="Ver servicio"
              size="small"
              (onClick)="handleSeeDetails(service)"
            />
          </div>

          <div class="options-documents">
            <p-toast />

            <p-button
              size="small"
              [icon]="loadingServiceOrder[service.id] ? 'pi pi-spin pi-spinner' : 'pi pi-folder-open'"
              pTooltip="Orden de servicio"
              tooltipPosition="top"
              severity="warning"
              [disabled]="loadingServiceOrder[service.id]"
              (onClick)="handleServiceOrderDocument(service)"
            />

            <p-button
              size="small"
              [icon]="loadingServiceCertificate[service.id] ? 'pi pi-spin pi-spinner' : 'pi pi-folder-open'"
              pTooltip="Certificado de servicio"
              tooltipPosition="top"
              severity="warning"
              [disabled]="loadingServiceCertificate[service.id]"
              (onClick)="handleServiceOrderCertificate(service)"
            />
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="2">
          No se encontraron servicios
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
