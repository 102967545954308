import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);
  private url = `${environment.apiUrl}/administrative/events/`;

  constructor() { }

  getEvents(page: number = 1, pageSize: number = 10): Observable<any> {
    const token = this._authService.getToken()!;
    const session = this._sessionService.getSession();
    const customerId = session!.customer.id;

    const eventsUrl = `${this.url}?customer=${customerId}&page=${page}&page_size=${pageSize}`;

    return this._http.get<any>(eventsUrl, { headers: { Authorization: `Bearer ${token}` } });
  }

  // getEvents(): Observable<Event[]> {}

  // getEventById(id: string): Observable<Event> {}

  // createEvent(event: Event): Observable<Event> {}

  // updateEvent(event: Event): Observable<Event> {}

  // deleteEvent(event: Event): Observable<void> {}
}
