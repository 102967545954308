import { Routes } from '@angular/router';

import { authGuard } from './auth/guards/auth/auth.guard';
import { isAuthGuard } from './auth/guards/is-auth/is-auth.guard';
import { ChangePasswordComponent } from './auth/pages/change-password/change-password.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { AreasComponent } from './dashboard/pages/areas/areas.component';
import { DashboardComponent } from './dashboard/pages/dashboard/dashboard.component';
import { EventDetailsComponent } from './dashboard/pages/event-details/event-details.component';
import { HomeComponent } from './dashboard/pages/home/home.component';
import { InspectionsComponent } from './dashboard/pages/inspections/inspections.component';
import { MipFoldersComponent } from './dashboard/pages/mip-folders/mip-folders.component';
import { ReportsComponent } from './dashboard/pages/reports/reports.component';
import { SignatureComponent } from './dashboard/pages/signature/signature.component';
import { TracingsComponent } from './dashboard/pages/tracings/tracings.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [authGuard]
  },
  {
    path: 'services',
    component: HomeComponent,
    canActivate: [authGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [authGuard]
  },
  {
    path: 'tracings',
    component: TracingsComponent,
    canActivate: [authGuard]
  },
  {
    path: 'mip-folders',
    component: MipFoldersComponent,
    canActivate: [authGuard]
  },
  {
    path: 'inspections',
    component: InspectionsComponent,
    canActivate: [authGuard]
  },
  {
    path: 'areas',
    component: AreasComponent,
    canActivate: [authGuard]
  },
  {
    path: 'event-details/:id',
    component: EventDetailsComponent,
    canActivate: [authGuard]
  },
  {
    path: 'signature/:id',
    component: SignatureComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [isAuthGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: '/login'
  }
];
