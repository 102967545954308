import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment.prod';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { ActionPlan } from '../../models/mip/action-plan.model';
import { AreaActivity, AreaActivityPhoto } from '../../models/mip/areas.model';
import { Condition, ConditionCleaning, ConditionPhoto } from '../../models/mip/condition.model';
import { Control, ControlPhoto, ControlProduct } from '../../models/mip/control.model';
import { EventRetrieve } from '../../models/mip/event-retrieve.model';
import { Inspection, InspectionData, InspectionPhoto } from '../../models/mip/inspection.model';
import { Payment } from '../../models/mip/payment.model';
import { Signature } from '../../models/mip/signature.model';
import { Station, StationPhoto } from '../../models/mip/station.model';
import { Task, TaskComment, TaskFile, TaskPhoto } from '../../models/mip/task.model';

export interface EventResponse {
  data: EventRetrieve;
  code: number;
  message: string;
}

export interface InspectionResponse {
  inspection: Inspection;
  inspectionData: InspectionData[];
  inspectionPhotos: InspectionPhoto[];
}

export interface ConditionResponse {
  condition: Condition;
  conditionCleanings: ConditionCleaning[];
  conditionPhotos: ConditionPhoto[];
}

export interface ControlPlagueResponse {
  control: Control;
  controlFormProducts: ControlProduct[];
  controlFormsPhotos: ControlPhoto[];
}

export interface StationCountsResponse {
  station: Station;
  stationPhotos: StationPhoto[];
}

export interface ControlAreasResponse {
  areaActivity: AreaActivity;
  areaActivityPhotos: AreaActivityPhoto[];
}

export interface TaskResponse {
  task: Task;
  taskComments: TaskComment[];
  taskFiles: TaskFile[];
  taskPhotos: TaskPhoto[];
}

@Injectable({
  providedIn: 'root'
})
export class MipDetailsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _baseUrl = `${environment.apiUrl}/administrative/mip`;
  private _taskUrl = `${environment.apiUrl}/administrative/tasks`;

  getEvent(eventId: string): Observable<EventResponse> {
    const token = this._authService.getToken()!;
    const url = `${environment.apiUrl}/administrative/events/${eventId}/`;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<EventResponse>(url, { headers });
  }

  getEventDetails(eventId: string): Observable<any> {
    const token = this._authService.getToken()!;
    const url = `${environment.apiUrl}/administrative/events/${eventId}/get_event_customer`;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<any>(url, { headers });
  }

  getEventCustomer(customerId: string): Observable<any> {
    const token = this._authService.getToken()!;
    const url = `${environment.apiUrl}/administrative/customers/${customerId}/`;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<any>(url, { headers });
  }

  getEventType(eventTypeId: string): Observable<any> {
    const token = this._authService.getToken()!;
    const url = `${environment.apiUrl}/administrative/catalogs/event-types/${eventTypeId}/`;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<any>(url, { headers });
  }

  getEventServiceType(serviceTypeId: string): Observable<any> {
    const token = this._authService.getToken()!;
    const url = `${environment.apiUrl}/administrative/catalogs/service-types/${serviceTypeId}/`;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<any>(url, { headers });
  }

  getInspections(eventId: string): Observable<InspectionResponse[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return forkJoin({
      inspectionForms: this._http.get<Inspection[]>(`${this._baseUrl}/inspection-forms/?event=${eventId}`, { headers }),
      inspectionFormsData: this._http.get<InspectionData[]>(`${this._baseUrl}/inspection-forms-data/`, { headers }),
      inspectionFormsPhotos: this._http.get<InspectionPhoto[]>(`${this._baseUrl}/inspection-forms-photo/`, { headers })
    }).pipe(
      map((responses) => {
        const inspections = responses.inspectionForms.map((inspection) => {
          const inspectionData = responses.inspectionFormsData.filter((data) => data.inspection_form === inspection.id);
          const inspectionPhotos = responses.inspectionFormsPhotos.filter((photo) => photo.inspection_form === inspection.id);

          return {
            inspection,
            inspectionData,
            inspectionPhotos
          };
        });

        return inspections;
      })
    );
  }

  getConditions(eventId: string): Observable<ConditionResponse[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return forkJoin({
      conditionForms: this._http.get<Condition[]>(`${this._baseUrl}/condition-forms/?event=${eventId}`, { headers }),
      conditionsFormCleanings: this._http.get<ConditionCleaning[]>(`${this._baseUrl}/condition-forms-cleaning/`, { headers }),
      conditionsFormPhotos: this._http.get<ConditionPhoto[]>(`${this._baseUrl}/condition-forms-photo/`, { headers })
    }).pipe(
      map((responses) => {
        const conditions = responses.conditionForms.map((condition) => {
          const conditionCleanings = responses.conditionsFormCleanings.filter((cleaning) => cleaning.condition === condition.id);
          const conditionPhotos = responses.conditionsFormPhotos.filter((photo) => photo.condition === condition.id);

          return {
            condition,
            conditionCleanings,
            conditionPhotos
          };
        });

        return conditions;
      })
    );
  }

  getControlPlagues(eventId: string): Observable<ControlPlagueResponse[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return forkJoin({
      controlForms: this._http.get<Control[]>(`${this._baseUrl}/control-forms/?event=${eventId}`, { headers }),
      controlFormProducts: this._http.get<ControlProduct[]>(`${this._baseUrl}/control-forms-products/`, { headers }),
      controlFormsPhoto: this._http.get<ControlPhoto[]>(`${this._baseUrl}/control-forms-photo/`, { headers })
    }).pipe(
      map((responses) => {
        const controlAreas = responses.controlForms.map((control) => {
          const controlFormProducts = responses.controlFormProducts.filter((product) => product.ControlForm === control.id);
          const controlFormsPhotos = responses.controlFormsPhoto.filter((photo) => photo.ControlForm === control.id);

          return {
            control,
            controlFormProducts,
            controlFormsPhotos
          };
        });

        return controlAreas;
      })
    );
  }

  getStationCounts(eventId: string): Observable<StationCountsResponse[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return forkJoin({
      stationCountData: this._http.get<Station[]>(`${this._baseUrl}/station-count-data/?event=${eventId}`, { headers }),
      stationCountPhotos: this._http.get<StationPhoto[]>(`${this._baseUrl}/station-count-photo/`, { headers })
    }).pipe(
      map((responses) => {
        const stationCounts = responses.stationCountData.map((station) => {
          const stationPhotos = responses.stationCountPhotos.filter((photo) => photo.station === station.id);

          return {
            station,
            stationPhotos
          };
        });

        return stationCounts;
      })
    );
  }

  getActionPlans(eventId: string): Observable<ActionPlan[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<ActionPlan[]>(
      `${this._baseUrl}/action-plans/?event=${eventId}`,
      { headers }
    );
  }

  getPayments(eventId: string): Observable<Payment[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<Payment[]>(
      `${this._baseUrl}/payment-forms/?event=${eventId}`,
      { headers }
    );
  }

  getSignatures(eventId: string): Observable<Signature[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return this._http.get<Signature[]>(
      `${this._baseUrl}/signature-forms/?event=${eventId}`,
      { headers }
    );
  }

  getTasks(eventId: string): Observable<TaskResponse[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return forkJoin({
      tasksData: this._http.get<Task[]>(`${this._taskUrl}/tasks/?event=${eventId}`, { headers }),
      tasksComments: this._http.get<TaskComment[]>(`${this._taskUrl}/task-comments/`, { headers }),
      tasksFiles: this._http.get<TaskFile[]>(`${this._taskUrl}/task-files/`, { headers }),
      tasksPhotos: this._http.get<TaskPhoto[]>(`${this._taskUrl}/task-photo/`, { headers })
    }).pipe(
      map((responses) => {
        const tasks = responses.tasksData.map((task) => {
          const taskComments = responses.tasksComments.filter((comment) => comment.task === task.id);
          const taskFiles = responses.tasksFiles.filter((file) => file.task === task.id);
          const taskPhotos = responses.tasksPhotos.filter((photo) => photo.task === task.id);

          return {
            task,
            taskComments,
            taskFiles,
            taskPhotos
          };
        });

        return tasks;
      })
    );
  }

  getControlAreas(eventId: string): Observable<ControlAreasResponse[]> {
    const token = this._authService.getToken()!;
    const headers = { Authorization: `Bearer ${token}` };

    return forkJoin({
      areasActivities: this._http.get<AreaActivity[]>(`${this._baseUrl}/areas-activity/?event=${eventId}`, { headers }),
      areasActivityPhotos: this._http.get<AreaActivityPhoto[]>(`${this._baseUrl}/area-activity-photo/`, { headers })
    }).pipe(
      map((responses) => {
        const areas = responses.areasActivities.map((areaActivity) => {
          const areaActivityPhotos = responses.areasActivityPhotos.filter((photo) => photo.area_activity === areaActivity.id);

          return {
            areaActivity,
            areaActivityPhotos
          };
        });

        return areas;
      })
    );
  }
}
