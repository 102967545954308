<p-autoComplete
  [suggestions]="filteredStatuses"
  [showEmptyMessage]="true"
  appendTo="body"
  [dropdown]="true"
  field="name"
  [minLength]="1"
  (completeMethod)="filterStatuses($event)"
  class="w-full"
  (onSelect)="handleSelectStatus($event)"
>
</p-autoComplete>
