import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';
import { AreaPhotosResponse, AreasResponse, InspectionDataResponse, InspectionResponse, ZoneResponse } from '../../models/areas.model';

@Injectable({
  providedIn: 'root'
})
export class AreasService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);

  private _baseUrl = `${environment.apiUrl}/administrative/mip`;
  private _eventsUrl = `${environment.apiUrl}/administrative/events`;
  private _pestControlUrl = `${environment.apiUrl}/administrative/pest-control`;

  getAreas(): Observable<AreasResponse[]> {
    const token = this._authService.getToken();
    let uri = `${this._baseUrl}/station-count-data/`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<AreasResponse[]>(
      uri,
      { headers }
    );
  }

  getAreasPhotos(areaId: string): Observable<AreaPhotosResponse[]> {
    const token = this._authService.getToken();
    let uri = `${this._baseUrl}/station-count-photo/?station=${areaId}`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<AreaPhotosResponse[]>(
      uri,
      { headers }
    );
  }

  getInspectionsForms(): Observable<InspectionResponse[]> {
    const token = this._authService.getToken();
    let uri = `${this._baseUrl}/inspection-forms/`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<InspectionResponse[]>(
      uri,
      { headers }
    );
  }

  getInspectionsFormsByEvent(eventId: string): Observable<InspectionResponse[]> {
    const token = this._authService.getToken();
    let uri = `${this._baseUrl}/inspection-forms/?event=${eventId}`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<InspectionResponse[]>(
      uri,
      { headers }
    );
  }

  getInspectionsData(formId: string): Observable<InspectionDataResponse[]> {
    const token = this._authService.getToken();
    let uri = `${this._baseUrl}/inspection-forms-data/?inspection_form=${formId}`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<InspectionDataResponse[]>(
      uri,
      { headers }
    );
  }

  getInspectionPhotos(formId: string): Observable<InspectionDataResponse[]> {
    const token = this._authService.getToken();
    let uri = `${this._baseUrl}/inspection-forms-photo/?inspection_form=${formId}`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<InspectionDataResponse[]>(
      uri,
      { headers }
    );
  }

  getEventData(eventId: string): Observable<any> {
    const token = this._authService.getToken();
    let uri = `${this._eventsUrl}/${eventId}/`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<any>(
      uri,
      { headers }
    );
  }

  getZone(zoneId: string): Observable<ZoneResponse> {
    const token = this._authService.getToken();
    let uri = `${this._pestControlUrl}/zones/${zoneId}/`;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<ZoneResponse>(
      uri,
      { headers }
    );
  }
}
