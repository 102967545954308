<div class="home-element">
  <h2>
    <i class="pi pi-calendar"></i> Mis Servicios Programados
  </h2>

  <p-table
    #dt2
    [value]="services"
    dataKey="id"
    [rows]="dataForm.value.page_size"
    [rowsPerPageOptions]="[10, 25, 50]"
    [totalRecords]="dataForm.value.totalRecords"
    [lazy]="true"
    [showCurrentPageReport]="true"
    [loading]="loading"
    [paginator]="false"
    sortMode="multiple"
    styleClass="p-datatable-sm"
    [globalFilterFields]="['folio', 'title', 'status.name', 'employee.name']"
    [tableStyle]="{ 'min-width': '80rem' }"
  >
    <ng-template pTemplate="caption">
      <div class="filters-row">
        <div>
          <p-iconField iconPosition="left" class="ml-auto">
            <p-inputIcon>
              <i class="pi pi-search"></i>
            </p-inputIcon>

            <input
              pInputText
              type="text"
              (input)="onFilterInput($event, 'search')"
              placeholder="Buscar"
            />
          </p-iconField>
        </div>

        <p-button
          (click)="resetFilters()"
          type="button"
          icon="pi pi-filter-slash"
          styleClass="p-button-rounded"
          severity="danger"
        >
        </p-button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 15%" pSortableColumn="folio" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Folio
            <p-sortIcon field="folio" />
          </p>
        </th>

        <th style="width: 20%" pSortableColumn="title" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Nombre
            <p-sortIcon field="title" />
          </p>
        </th>

        <th style="width: 15%" pSortableColumn="initial_date" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Fecha y hora de servicio
            <p-sortIcon field="initial_date" />
          </p>
        </th>

        <th style="width: 15%" pSortableColumn="status.name" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Estado
            <p-sortIcon field="status.name" />
          </p>
        </th>

        <th style="width: 20%" pSortableColumn="employee.name" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Técnico
            <p-sortIcon field="employee.name" />
          </p>
        </th>
      </tr>

      <tr>
        <th>
          <input
            pInputText
            type="text"
            (input)="onFilterInput($event, 'search')"
            placeholder="Folio"
            class="w-full"
          >
        </th>

        <th>
          <input
            pInputText
            type="text"
            (input)="onFilterInput($event, 'search')"
            placeholder="Nombre"
            class="w-full"
          >
        </th>

        <th>
          <p-calendar
            [readonlyInput]="true"
            appendTo="body"
            (onSelect)="onSearchDate($event)"
            [showIcon]="true"
          ></p-calendar>
        </th>

        <th>
          <app-status-filter (onSelectStatus)="onSelectStatus($event)"></app-status-filter>
        </th>

        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-service>
      <tr class="text-sm">
        <td>
          <div class="dropdown">
            @if (service.status.name === 'Completado' || service.status.name === 'Finalizado' || service.status.name === 'Sincronizado') {
              <span
                class="fw-bold dropdown-toggle text-primary th-inside"
                type="button"
                (click)="toggleMenu($event, service)"
              >
                {{ service.folio }}
                <i class="pi pi-angle-down ml-1"></i>
              </span>

              <p-menu #menuWithItems [model]="menuItems" popup appendTo="body"></p-menu>
            } @else {
              <span
                class="fw-bold dropdown-toggle text-primary th-inside"
                type="button"
                (click)="menuWithoutItems.toggle($event)"
              >
                {{ service.folio }}
                <i class="pi pi-angle-down ml-1"></i>
              </span>

              <p-menu #menuWithoutItems [model]="menuNoItems" popup appendTo="body"></p-menu>
            }
          </div>
        </td>

        <td>{{ service.title }}</td>

        <td class="date-in-table">
          {{ service.initial_date | date: 'dd/MM/yyyy' }}
          <br />
          {{ formatTime(service.initial_hour) }}
        </td>

        <td>
          <p-tag
            [value]="service.status.name"
            [severity]="getSeverity(service.status.name)"
          />
        </td>

        <td>{{ service.employee.name }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="2">
          No se encontraron servicios
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-paginator
    [first]="dataForm.value.firstPage"
    [rows]="dataForm.value.page_size"
    [rowsPerPageOptions]="[10, 20, 30, 50]"
    [totalRecords]="dataForm.value.totalRecords"
    (onPageChange)="pageChange($event)"
    currentPageReportTemplate="{currentPage} de {totalPages}"
    [showJumpToPageDropdown]="true"
    [showPageLinks]="true"
    [showJumpToPageInput]="true"
  ></p-paginator>
</div>
