import { ChangeDetectorRef, Component, inject, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventAddArg, EventApi, EventChangeArg, EventClickArg, EventInput, EventRemoveArg } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import { environment } from '../../../../environments/environment.prod';
import { SessionService } from '../../../auth/services/session/session.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import type { Document as DocumentType } from '../../models/documents.model';
import type { Event } from '../../models/events.model';
import { SafeUrlPipe } from '../../pipes/safe-url/safe-url.pipe';
import { DocumentsService } from '../../services/documents/documents.service';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    FullCalendarModule,
    DialogModule,
    ButtonModule,
    SafeUrlPipe
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;

  private _eventsService = inject(EventsService);
  private _documentsService = inject(DocumentsService);
  private _loadingService = inject(LoadingService);
  private _sessionService = inject(SessionService);

  public events: any[] = [];
  public documents: DocumentType[] = [];

  public currentEvents: EventApi[] = [];
  public calendarVisible = true;
  public calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    locale: esLocale,
    timeZone: 'locale',
    initialView: 'timeGridWeek',
    initialEvents: this.eventSources,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    slotMinTime: '07:00:00',
    slotMaxTime: '23:00:00',
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventAdd: this.handleEventAdd.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventRemove: this.handleEventRemove.bind(this),
    datesSet: this.handleDatesSet.bind(this),

    eventContent: function (arg: any) {
      let title = arg.event.title;

      if (arg.view.type == "dayGridMonth") {
        title = arg.event.title.length > 28 ? arg.event.title.substring(0, 28) + '...' : arg.event.title;
        return { html: `<span style="font-size:10px; font-weight:bold;">${title}</span>` };
      }

      if (arg.view.type == "timeGridWeek") {
        title = arg.event.title;
      }

      return { html: `<span>${title}</span>` };
    }
  };

  public isModalVisible: boolean = false;
  public iframeModalVisible: boolean = false;
  public selectedEvent: Event | null = null;
  public selectedDocument: DocumentType | null = null;
  public documentUrl: string | null = null;
  public isModalLoading: boolean = false;
  public isForCalendar: boolean = false;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    const currentDate = new Date();

    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ).toISOString().split('T')[0];

    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).toISOString().split('T')[0];

    this.loadEvents(startOfMonth, endOfMonth);
  }

  isForThisCustomer() {
    const session = this._sessionService.getSession();
    const customerId = session!.customer.id;

    return customerId === '9b9ede61-27dd-4454-a19d-13b440986423';
  }

  get eventSources(): EventInput[] {
    if (!this.events) {
      return [];
    }

    return this.events.map((event) => ({
      id: event.id,
      title: event.title + ' ' + this.getStatusName(event),
      // title: `${event.customer?.name} =>` + ' ' + this.getStatusName(event),
      start: `${event.initial_date} ${event.initial_hour}`,
      end: `${event.final_date} ${event.final_hour}`,
      color: this.getBackgroundColor(event),
      backgroundColor: this.getBackgroundColor(event),
      borderColor: this.getBackgroundColor(event),
      className: 'recurring-event',
    }));
  }

  loadEvents(initial_date?: string, final_date?: string) {
    const params: any = {};

    if (initial_date && final_date) {
      params.initial_date = initial_date;
      params.final_date = final_date;
    }

    this._loadingService.show();

    this._eventsService.getEvents(params).subscribe((data: any[]) => {
      this.events = data;

      const calendarApi = this.fullCalendar.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(this.eventSources);

      this._loadingService.hide();
    });
  }

  handleDatesSet(dateInfo: { start: Date, end: Date, startStr: string, endStr: string, view: any }) {
    const initialDate = dateInfo.startStr.split('T')[0];
    const finalDate = dateInfo.endStr.split('T')[0];

    this.loadEvents(initialDate, finalDate);
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    /*const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    } */

    console.log('SELECT INFO: ', selectInfo);
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.isModalLoading = true;

    const eventId = clickInfo.event.id;
    this.selectedEvent = this.events.find((event) => event.id === eventId) || null;

    this._documentsService.getDocuments(eventId).subscribe((response) => {
      this.documents = response.data;
      this.isModalLoading = false;
    });

    this.isModalVisible = true;
  }

  handleEvents(events: EventApi[]) {
    /* this.currentEvents = events;
    this.changeDetector.detectChanges(); */
  }

  handleEventAdd(event: EventAddArg) {
    console.log('EVENT ADD: ', event);
  }

  handleEventChange(event: EventChangeArg) {
    console.log('EVENT CHANGE: ', event);
  }

  handleEventRemove(event: EventRemoveArg) {
    console.log('EVENT REMOVE: ', event);
  }

  getBackgroundColor(event: any): string {
    switch (event.status.key_string) {
      case 'event-created':
        return '#3788d8';
      case 'event-in-progress':
        return '#fbc02d';
      case 'event-completed':
        return '#16A05A';
      case 'event-cancelled':
        return '#ef4444';
      default:
        return '#3C5898';
    }
  }

  getStatusName(event: any): string {
    switch (event.status.key_string) {
      case 'event-created':
        return "(PROGRAMADO)";
      case 'event-in-progress':
        return "(EN PROGRESO)";
      case 'event-completed':
        return "(COMPLETADO)";
      case 'event-cancelled':
        return "(CANCELADO)";
      default:
        return 'NA';
    }
  }

  handleOpenDocument(document: DocumentType) {
    this.isModalLoading = true;
    this.isModalVisible = false;
    this.iframeModalVisible = true;

    this.selectedDocument = document;

    this._documentsService.getDocumentInStorage(document.url).subscribe((response) => {
      this.documentUrl = environment.documentStorageUrl + response.data;
      this.isModalLoading = false;
    });
  }

  handleDownloadDocument(document: DocumentType) {
    this._documentsService.getDocumentInStorage(document.url).subscribe((response) => {
      const url = environment.documentStorageUrl + response.data;
      window.open(url, '_blank');
    });
  }

  handlePrintCalendar() {
    this.isForCalendar = true;
    this.isModalLoading = true;
    this.isModalVisible = false;
    this.iframeModalVisible = true;

    this._documentsService.getCalendarDocument().subscribe((response) => {
      this.documentUrl = environment.documentStorageUrl + response.data;
      this.isModalLoading = false;
    });
  }

  handleDownload() {
    const s3Route = `${environment.documentStorageUrl}certificate/ALIMENTOS BYD.pdf`
    window.open(s3Route, '_blank');
  }

  showDialog() {
    this.isModalVisible = true;
  }
}
