<app-layout>
  <p-toast />

  <div class="home-element">
    <header>
      <h2>
        <i class="pi pi-ticket"></i> Tickets de seguimiento
      </h2>

      <p-button
        (click)="isModalVisible = true"
        type="button"
        icon="pi pi-plus"
        styleClass="p-button-rounded"
      >
      </p-button>
    </header>

    <section class="table-container">
      <p-table
        #dt2
        [value]="tickets"
        dataKey="id"
        [rows]="dataForm.value.page_size"
        [rowsPerPageOptions]="[10, 20, 30, 50]"
        [loading]="loading"
        [paginator]="false"
        styleClass="p-datatable-sm"
        currentPageReportTemplate="{currentPage} de {totalPages}"
        [globalFilterFields]="['folio', 'close_date']"
        [tableStyle]="{ 'min-width': '135rem' }"
      >
        <ng-template pTemplate="caption">
          <div class="filters-row">
            <div>
              <p-iconField iconPosition="left" class="ml-auto">
                <p-inputIcon>
                  <i class="pi pi-search"></i>
                </p-inputIcon>

                <input
                  pInputText
                  type="text"
                  (input)="onFilterInput($event, 'search')"
                  placeholder="Buscar"
                />
              </p-iconField>
            </div>

            <p-button
              (click)="resetFilters()"
              type="button"
              icon="pi pi-filter-slash"
              styleClass="p-button-rounded"
              severity="danger"
            >
            </p-button>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th style="width:10%" pSortableColumn="folio" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                #Folio
                <p-sortIcon field="folio" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="status.name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Estado
                <p-sortIcon field="status.name" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="ticket_type.name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Tipo de ticket
                <p-sortIcon field="ticket_type.name" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="media.name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Media
                <p-sortIcon field="media.name" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="employee.name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Empleado
                <p-sortIcon field="employee.name" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="group.name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Grupo
                <p-sortIcon field="group.name" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="close_date" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Fecha
                <p-sortIcon field="close_date" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="area_category.name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Categoría de área
                <p-sortIcon field="area_category.name" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="plague_category.name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Categoría de plaga
                <p-sortIcon field="plague_category.name" />
              </p>
            </th>

            <th style="width:10%" pSortableColumn="comments" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Comentarios
                <p-sortIcon field="comments" />
              </p>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-ticket>
          <tr class="text-sm">
            <td>
              <span class="folio">
                {{ ticket.folio }}
              </span>
            </td>

            <td>
              @if (ticket.status.name === 'Creada') {
                <span class="state-opened">
                  {{ ticket.status.name }}
                </span>
              } @else {
                <span class="state-closed">
                  {{ ticket.status.name }}
                </span>
              }
            </td>

            <td>
              {{ ticket.ticket_type.name }}
            </td>

            <td>
              {{ ticket.media.name }}
            </td>

            <td>
              {{ ticket.employee.name }}
            </td>

            <td>
              {{ ticket.group.name }}
            </td>

            <td>
              {{ ticket.close_date | date: 'dd/MM/yyyy' }}
            </td>

            <td>
              {{ ticket.area_category.name }}
            </td>

            <td>
              {{ ticket.plague_category.name }}
            </td>

            <td>
              {{ ticket.comments }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">
              <p class="emptymessage">No se encontraron tickets</p>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-paginator
        [first]="dataForm.value.firstPage"
        [rows]="dataForm.value.page_size"
        [rowsPerPageOptions]="[10, 20, 30, 50]"
        [totalRecords]="dataForm.value.totalRecords"
        (onPageChange)="pageChange($event)"
        currentPageReportTemplate="{currentPage} de {totalPages}"
        [showJumpToPageDropdown]="true"
        [showPageLinks]="true"
        [showJumpToPageInput]="true"
      ></p-paginator>
    </section>

    <p-dialog
      [modal]="true"
      [(visible)]="isModalVisible"
      [style]="{ width: '50%', 'min-width': '30rem' }"
    >
      <ng-template pTemplate="header">
        <div class="dialog-header">
          <span class="dialog-header-title">
            <i class="pi pi-ticket"></i> Agregar nuevo ticket
          </span>
        </div>
      </ng-template>

      <form
        [formGroup]="tracingForm"
        novalidate
        (submit)="handleSave($event)"
        class="form"
      >
        <div class="mt-4 mb-4">
          <span class="w-100 p-fluid p-float-label">
            <p-autoComplete
              formControlName="ticketType"
              [suggestions]="filterTicketsTypes"
              [showEmptyMessage]="true"
              appendTo="body"
              [dropdown]="true"
              field="name"
              [minLength]="1"
              (completeMethod)="filterTickets($event)"
              class="w-full"
            >
            </p-autoComplete>

            <label for="autocomplete">
              Tipo de ticket
            </label>
          </span>
        </div>

        <div class="form-group mt-4 mb-4">
          <span class="p-fluid p-float-label">
            <p-autoComplete
              formControlName="areaCategory"
              [suggestions]="filterAreaCategories"
              [showEmptyMessage]="true"
              appendTo="body"
              [dropdown]="true"
              field="name"
              [minLength]="1"
              (completeMethod)="filterArea($event)"
              class="w-full"
            >
            </p-autoComplete>

            <label for="autocomplete">
              Categoría de área
            </label>
          </span>

          <span class="p-fluid p-float-label">
            <p-autoComplete
              formControlName="plagueCategory"
              [suggestions]="filterPlagueCategories"
              [showEmptyMessage]="true"
              appendTo="body"
              [dropdown]="true"
              field="name"
              [minLength]="1"
              (completeMethod)="filterPlague($event)"
              class="w-full"
            >
            </p-autoComplete>

            <label for="autocomplete">
              Categoría de plaga
            </label>
          </span>
        </div>

        <div class="w-full mt-4 mb-4">
          <textarea
            rows="3"
            pInputTextarea
            placeholder="Comentarios"
            formControlName="comments"
            class="w-full"
          >
          </textarea>
        </div>

        <div class="button-group mt-4">
          <button
            pButton
            pRipple
            type="submit"
            [disabled]="tracingForm.invalid"
            label="Guardar"
            class="p-button-raised"
            size="small"
          ></button>

          <button
            (click)="isModalVisible = false"
            pButton
            pRipple
            type="button"
            label="Cancelar"
            class="p-button-raised p-button-secondary"
            size="small"
          ></button>
        </div>
      </form>
    </p-dialog>
  </div>
</app-layout>
