import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment.prod';
import { AuthService } from '../../../auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);

  private url = `${environment.apiUrl}/administrative/catalogs/`;

  getStatuses(module: string = ''): any {
    const token = this._authService.getToken()!;

    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<any>(
      `${this.url}statuses/?module=${module}`,
      { headers }
    );
  }
}
