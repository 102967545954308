import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';
import { ConditionReportResponse } from '../../models/reports/condition-report.model';
import { InspectionReportResponse } from '../../models/reports/inspection-report.model';
import { ProductReportResponse } from '../../models/reports/product-report.model';
import { StationReportResponse } from '../../models/reports/station-report.model';
import { TrendPlagueResponse, TrendProductResponse, TrendStationResponse } from '../../models/reports/trends-report.model';
import { Plague, Product } from '../../models/reports/catalogs-report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);

  private _baseUrl = `${environment.apiUrl}/administrative/customers`;
  private _catalogsUrl = `${environment.apiUrl}/administrative`;

  getInspectionGraphic(initialDate?: string, finalDate?: string): Observable<InspectionReportResponse> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting inspection graphic'));
    }

    // const customerId = session.customer.id;
    const customerId = '7d27979f-7965-44e7-9c28-7d2b0d0e05cf';
    let uri = `${this._baseUrl}/${customerId}/inspectionGrafics/`;

    if (initialDate && finalDate) {
      uri += `?from_time=${initialDate}&to_time=${finalDate}`;
    } else if (initialDate) {
      uri += `?from_time=${initialDate}`;
    } else if (finalDate) {
      uri += `?to_time=${finalDate}`;
    }

    return this._http.get<InspectionReportResponse>(
      uri,
      { headers }
    );
  }

  getConditionGraphic(initialDate?: string, finalDate?: string): Observable<ConditionReportResponse> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting condition graphic'));
    }

    // const customerId = session.customer.id;
    const customerId = '7d27979f-7965-44e7-9c28-7d2b0d0e05cf';
    let uri = `${this._baseUrl}/${customerId}/conditionGrafics/`;

    if (initialDate && finalDate) {
      uri += `?from_time=${initialDate}&to_time=${finalDate}`;
    } else if (initialDate) {
      uri += `?from_time=${initialDate}`;
    } else if (finalDate) {
      uri += `?to_time=${finalDate}`;
    }

    return this._http.get<ConditionReportResponse>(
      uri,
      { headers }
    );
  }

  getProductGraphic(initialDate?: string, finalDate?: string): Observable<ProductReportResponse> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting product graphic'));
    }

    // const customerId = session.customer.id;
    const customerId = '7d27979f-7965-44e7-9c28-7d2b0d0e05cf';
    let uri = `${this._baseUrl}/${customerId}/productGrafics/`;

    if (initialDate && finalDate) {
      uri += `?from_time=${initialDate}&to_time=${finalDate}`;
    } else if (initialDate) {
      uri += `?from_time=${initialDate}`;
    } else if (finalDate) {
      uri += `?to_time=${finalDate}`;
    }

    return this._http.get<ProductReportResponse>(
      uri,
      { headers }
    );
  }

  getStationGraphic(initialDate?: string, finalDate?: string): Observable<StationReportResponse> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting station graphic'));
    }

    // const customerId = session.customer.id;
    const customerId = '7d27979f-7965-44e7-9c28-7d2b0d0e05cf';
    let uri = `${this._baseUrl}/${customerId}/StationGrafics/`;

    if (initialDate && finalDate) {
      uri += `?from_time=${initialDate}&to_time=${finalDate}`;
    } else if (initialDate) {
      uri += `?from_time=${initialDate}`;
    } else if (finalDate) {
      uri += `?to_time=${finalDate}`;
    }

    return this._http.get<StationReportResponse>(
      uri,
      { headers }
    );
  }

  getTrendPlagueGraphic(initialDate?: string, finalDate?: string): Observable<TrendPlagueResponse> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting plague graphic'));
    }

    // const customerId = session.customer.id;
    const customerId = '7d27979f-7965-44e7-9c28-7d2b0d0e05cf';
    let uri = `${this._baseUrl}/${customerId}/trendinfection/`;

    if (initialDate && finalDate) {
      uri += `?from_time=${initialDate}&to_time=${finalDate}`;
    } else if (initialDate) {
      uri += `?from_time=${initialDate}`;
    } else if (finalDate) {
      uri += `?to_time=${finalDate}`;
    }

    return this._http.get<TrendPlagueResponse>(
      uri,
      { headers }
    );
  }

  getTrendProductGraphic(initialDate?: string, finalDate?: string): Observable<TrendProductResponse> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting product graphic'));
    }

    // const customerId = session.customer.id;
    const customerId = '7d27979f-7965-44e7-9c28-7d2b0d0e05cf';
    let uri = `${this._baseUrl}/${customerId}/trendProduct/`;

    if (initialDate && finalDate) {
      uri += `?from_time=${initialDate}&to_time=${finalDate}`;
    } else if (initialDate) {
      uri += `?from_time=${initialDate}`;
    } else if (finalDate) {
      uri += `?to_time=${finalDate}`;
    }

    return this._http.get<TrendProductResponse>(
      uri,
      { headers }
    );
  }

  getTrendStationGraphic(initialDate?: string, finalDate?: string): Observable<TrendStationResponse> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting station graphic'));
    }

    // const customerId = session.customer.id;
    const customerId = '7d27979f-7965-44e7-9c28-7d2b0d0e05cf';
    let uri = `${this._baseUrl}/${customerId}/TendStations/`;

    if (initialDate && finalDate) {
      uri += `?from_time=${initialDate}&to_time=${finalDate}`;
    } else if (initialDate) {
      uri += `?from_time=${initialDate}`;
    } else if (finalDate) {
      uri += `?to_time=${finalDate}`;
    }

    return this._http.get<TrendStationResponse>(
      uri,
      { headers }
    );
  }

  getPlaguesList(): Observable<Plague[]> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting plagues list'));
    }

    const jobCenterId = session.customer.job_center.id;

    return this._http.get<Plague[]>(
      `${this._catalogsUrl}/catalogs/plagues/?job_center=${jobCenterId}`,
      { headers }
    );
  }

  getProductsList(): Observable<Product[]> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting products list'));
    }

    const jobCenterId = session.customer.job_center.id;

    return this._http.get<Product[]>(
      `${this._catalogsUrl}/inventories/products/?job_center=${jobCenterId}`,
      { headers }
    );
  }
}
