import { ChangeDetectorRef, Component, inject, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventAddArg, EventApi, EventChangeArg, EventClickArg, EventInput, EventRemoveArg } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import { environment } from '../../../../environments/environment';
import { STATUS_COLORS } from '../../../shared/constants/colors';
import type { Document as DocumentType } from '../../models/documents.model';
import type { Event } from '../../models/events.model';
import { SafeUrlPipe } from '../../pipes/safe-url/safe-url.pipe';
import { DocumentsService } from '../../services/documents/documents.service';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [FullCalendarModule, DialogModule, ButtonModule, SafeUrlPipe],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;

  private _eventsService = inject(EventsService);
  private _documentsService = inject(DocumentsService);

  public events: Event[] = [];
  public documents: DocumentType[] = [];

  public currentEvents: EventApi[] = [];
  public calendarVisible = true;
  public calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    locale: esLocale,
    initialView: 'dayGridMonth',
    initialEvents: this.eventSources,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventAdd: this.handleEventAdd.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventRemove: this.handleEventRemove.bind(this),
  };

  public isModalVisible: boolean = false;
  public iframeModalVisible: boolean = false;
  public selectedEvent: Event | null = null;
  public selectedDocument: DocumentType | null = null;
  public documentUrl: string | null = null;
  public isModalLoading: boolean = false;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loadEvents();
  }

  get eventSources(): EventInput[] {
    return this.events.map((event) => ({
      id: event.id,
      title: event.title + ' (' + event.status.name + ')',
      start: event.initial_date,
      end: event.final_date,
      color: STATUS_COLORS[event.status.name]
    }));
  }

  loadEvents() {
    this._eventsService.getEvents().subscribe((data: {
      items: Event[];
    }) => {
      this.events = data.items;

      const calendarApi = this.fullCalendar.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(this.eventSources);
    });
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    /*const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    } */

    console.log('SELECT INFO: ', selectInfo);
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.isModalLoading = true;

    const eventId = clickInfo.event.id;
    this.selectedEvent = this.events.find((event) => event.id === eventId) || null;

    this._documentsService.getDocuments(eventId).subscribe((response) => {
      this.documents = response.data;
      this.isModalLoading = false;
    });

    this.isModalVisible = true;
  }

  handleEvents(events: EventApi[]) {
    /* this.currentEvents = events;
    this.changeDetector.detectChanges(); */

    console.log('EVENTS: ', events);
  }

  handleEventAdd(event: EventAddArg) {
    console.log('EVENT ADD: ', event);
  }

  handleEventChange(event: EventChangeArg) {
    console.log('EVENT CHANGE: ', event);
  }

  handleEventRemove(event: EventRemoveArg) {
    console.log('EVENT REMOVE: ', event);
  }

  handleOpenDocument(document: DocumentType) {
    this.isModalLoading = true;
    this.isModalVisible = false;
    this.iframeModalVisible = true;

    this.selectedDocument = document;

    this._documentsService.getDocumentInStorage(document.url).subscribe((response) => {
      this.documentUrl = environment.documentStorageUrl + response.data;
      this.isModalLoading = false;
    });
  }

  handleDownloadDocument(document: DocumentType) {
    this._documentsService.getDocumentInStorage(document.url).subscribe((response) => {
      const url = environment.documentStorageUrl + response.data;
      window.open(url, '_blank');
    });
  }

  showDialog() {
    this.isModalVisible = true;
  }
}
