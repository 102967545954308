import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  private _http = inject(HttpClient);
  private _baseUrl = `${environment.apiUrl}/administrative`;

  saveSignature(signature: any): Observable<any> {
    const formData = new FormData();

    formData.append('event', signature.event);
    formData.append('signature', signature.signature);
    formData.append('name', signature.name);
    formData.append('job_position', signature.job_position);
    formData.append('comments', signature.comments ?? '');

    return this._http.post<any>(`${this._baseUrl}/mip/signature-forms/`, formData);
  }
}
