<main class="main-container">
  <p-toast />

  <div class="home-element">
    @if (signatureSaved) {
      <h2 class="success-message">
        <i class="pi pi-check"></i> Firma guardada correctamente
      </h2>
    } @else {
      <header>
        <h2>
          <i class="pi pi-pencil"></i> Firma
        </h2>
      </header>

      <form (ngSubmit)="accept()" [formGroup]="Form">
        <div class="form-container">
          <div class="draw-header">
            <label>
              Dibuja tu firma aquí
              <span class="required">(*)</span>
            </label>

            <button (click)="clear()" pButton pRipple type="button" icon="pi pi-trash" [rounded]="true" class="p-button-sm" severity="danger">
            </button>
          </div>

          <div (window:resize)="onResize()" #wrapper style="width: 100%;">
            <canvas #digital_sign id="firma" class="signature-pad" style="touch-action: none;" [width]="CanvasWidth"
              [height]="CanvasHeight"></canvas>
          </div>
        </div>

        <div class="form-container inputs-container">
          <div class="inputs-row">
            <label for="name">
              Nombre
              <span class="required">(*)</span>
            </label>

            <input formControlName="name" type="text" pInputText placeholder="Nombre" />
          </div>

          <div class="inputs-row">
            <label for="job_position">
              Puesto
              <span class="required">(*)</span>
            </label>

            <input formControlName="job_position" type="text" pInputText placeholder="Puesto" />
          </div>
        </div>

        <div class="inputs-row">
          <label for="commenst">
            Comentarios
          </label>

          <textarea formControlName="comments" pInputTextarea placeholder="Comentarios" [rows]="2"></textarea>
        </div>

        <div class="buttons-row">
          <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Aceptar"
            class="p-button-raised me-2 btn-sm"></button>

          <button (click)="cancel()" pButton pRipple type="button" label="Cancelar"
            class="p-button-raised p-button-secondary btn-sm"></button>
        </div>
      </form>
    }
  </div>
</main>
