<app-layout>
  <header>
    <h2>
      <i class="pi pi-folder-open"></i> Carpeta MIP
    </h2>

    <form
      [formGroup]="reportsForm"
      novalidate
      (submit)="onSubmit($event)"
      class="form"
    >
      <figcaption class="form-figcaption">
        Debes filtrar los resultados por fecha
      </figcaption>

      <div class="form-group">
        <p-calendar
          formControlName="initialDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="Fecha de inicio"
        />

        <p-calendar
          formControlName="finalDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="Fecha de final"
        />
      </div>

      <div class="form-group">
        <div class="checkbox-group">
          <p-checkbox
            formControlName="OR"
            value="OR"
            inputId="or"
          />

          <label for="or">Orden de servicio</label>
        </div>

        <div class="checkbox-group">
          <p-checkbox
            formControlName="RP"
            value="RP"
            inputId="rp"
          />

          <label for="rp">Reporte</label>
        </div>

        <div class="checkbox-group">
          <p-checkbox
            formControlName="IN"
            value="IN"
            inputId="in"
          />

          <label for="in">Inspección de estaciones</label>
        </div>

        <div class="checkbox-group">
          <p-checkbox
            formControlName="CT"
            value="CT"
            inputId="ct"
          />

          <label for="ct">Certificado</label>
        </div>

        <div class="checkbox-group">
          <p-checkbox
            formControlName="QO"
            value="QO"
            inputId="qo"
          />

          <label for="qo">Cotización</label>
        </div>
      </div>

      <p-button
        type="submit"
        class="button"
        label="Descargar carpeta MIP"
        icon="pi pi-file-pdf"
      >
        <!-- <i class="pi pi-file-pdf"></i> Descargar carpeta MIP -->
      </p-button>
    </form>
  </header>

  <p-dialog
    [modal]="true"
    [(visible)]="iframeModalVisible"
    [style]="{ width: '80%' }"
  >
    <ng-template pTemplate="header">
      <div class="dialog-header">
        <i class="pi pi-file-pdf" style="font-size: 2rem"></i>

        <span class="dialog-header-title">
          Carpeta MIP
        </span>
      </div>
    </ng-template>

    @if (isModalLoading) {
      <span class="no-documents">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </span>
    }

    @if (documentUrl) {
      <div>
        <iframe
          [src]="documentUrl | safeUrl"
          width="100%"
          height="500"
          frameborder="0"
        ></iframe>
      </div>
    }
  </p-dialog>
</app-layout>
