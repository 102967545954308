<app-layout>
  <div class="home-element">
    @if (event) {
      <section>
        <h2 class="header">
          Detalles del servicio: <span class="header-title">{{ event.event.title }}</span>
        </h2>

        <div class="customer-info">
          <p class="section-title">Atención a</p>

          <p class="customer-details">
            <span class="customer-name">{{ event.event.customer?.name }}</span>
            <br>
            <span class="customer-field">Dirección: </span>{{ event.event.customer?.address }}
            <br>
            <span class="customer-field">Teléfono: </span>{{ event.event.customer?.phone }}
            <br>
            <span class="customer-field">Email: </span>{{ event.event.customer?.email }}
            <br>
            <span class="customer-field">Nombre de contacto: </span>{{ event.event.customer?.contact_name }}
            <br>
            <span class="customer-field">Teléfono de contacto: </span>{{ event.event.customer?.contact_phone }}
            <br>
            <span class="customer-field">Email de contacto: </span>{{ event.event.customer?.contact_email }}
          </p>
        </div>

        <div class="event-details">
          <p class="detail">
            <span class="detail-title">Folio: </span>
            {{ event.event.folio }}
          </p>

          <p class="detail">
            <span class="detail-title">Tipo de evento: </span>
            {{ event.event.eventType?.name }}
          </p>

          <p class="detail">
            <span class="detail-title">Tipo de servicio: </span>
            {{ event.event.serviceType?.name }}
          </p>

          <p class="detail">
            <span class="detail-title">Total: </span>
            {{ event.event.total | currency }}
          </p>

          <p class="detail">
            <span class="detail-title">Fecha de inicio: </span>
            {{ event.event.initial_date | date: 'dd/MM/yyyy' }} - {{ event.event.initial_hour }}
          </p>

          <p class="detail">
            <span class="detail-title">Fecha de finalización: </span>
            {{ event.event.final_date | date: 'dd/MM/yyyy' }} - {{ event.event.final_hour }}
          </p>

          @if (event.event.comments && event.event.comments.length > 0) {
            <p class="detail">
              <span class="detail-title">Comentarios: </span>
              {{ event.event.comments }}
            </p>
          }
        </div>

        @if (event.inspection_forms.length > 0) {
          <div class="table-title">
            <h3>Inspecciones del lugar</h3>
          </div>

          <p-table
            [value]="event.inspection_forms"
            dataKey="id"
            [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            [expandedRowKeys]="expandedInspectionsRows"
            (onRowExpand)="onRowExpandInspection($event)"
            (onRowCollapse)="onRowCollapseInspection($event)"
            styleClass="p-datatable-sm p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 3rem" class="custom-header"></th>
                <th style="font-weight: bold;" class="custom-header">Tipo de Plaga</th>
                <th style="font-weight: bold;" class="custom-header">Número de Inspecciones</th>
                <th style="font-weight: bold;" class="custom-header">Fotos</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-inspection let-expanded="expanded">
              <tr class="text-sm">
                <td>
                  <p-button
                    type="button"
                    pRipple
                    [pRowToggler]="inspection"
                    [text]="true"
                    [rounded]="true"
                    [plain]="true"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                  />
                </td>

                <td>{{ inspection.plague_category }}</td>
                <td>{{ inspection.inspection_data.length }}</td>

                <td>
                  <button
                    pButton
                    type="button"
                    icon="pi pi-image"
                    [rounded]="true"
                    (click)="openPhotoModalInspection(inspection)"
                  >
                  </button>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-inspection>
              <tr>
                <td colspan="4">
                  <div class="p-3">
                    <p-table
                      [value]="inspection.inspection_data"
                      dataKey="plague"
                      styleClass="p-datatable-sm p-datatable-striped"
                    >
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="font-weight: bold;">Plaga</th>
                          <th style="font-weight: bold;">Grado de Infestación</th>
                          <th style="font-weight: bold;">Tipo de Evidencia</th>
                          <th style="font-weight: bold;">Ciclo de Vida</th>
                          <th style="font-weight: bold;">Áreas</th>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="body" let-detail>
                        <tr class="text-sm">
                          <td>{{ detail.plague }}</td>
                          <td>{{ detail.infestation_degree }}</td>
                          <td>{{ detail.evidence_types.join(', ') }}</td>
                          <td>{{ detail.life_cycle_plague.join(', ') }}</td>
                          <td>{{ detail.nesting_area.join(', ') }}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <p-dialog [(visible)]="isInspectionPhotoModalVisible" [modal]="true" [header]="'Fotos de Inspección'" [style]="{width: '50vw'}">
            <ng-template pTemplate="content">
              <p-carousel [value]="selectedInspection?.photos" [numVisible]="1" [numScroll]="1">
                <ng-template pTemplate="item" let-photo>
                  <div class="photo-container">
                    <img
                      [src]="photo.photo"
                      alt="Foto de inspección"
                      style="width: 100%; border-radius: 5px;"
                    >

                    <p>
                      <strong>Área: </strong>
                      {{ photo?.nesting_area }}
                    </p>

                    <p>
                      <strong>Comentarios: </strong>
                      {{ photo?.comments }}
                    </p>
                  </div>
                </ng-template>
              </p-carousel>
            </ng-template>
          </p-dialog>
        }

        @if (event.condition.length > 0) {
          <div class="table-title">
            <h3>Condiciones del lugar</h3>
          </div>

          <p-table
            [value]="event.condition"
            dataKey="id"
            [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            [expandedRowKeys]="expandedConditionsRows"
            (onRowExpand)="onRowExpandCondition($event)"
            (onRowCollapse)="onRowCollapseCondition($event)"
            styleClass="p-datatable-sm p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 3rem" class="custom-header"></th>
                <th style="font-weight: bold;" class="custom-header">Nombre de la condición</th>
                <th style="font-weight: bold;" class="custom-header">Número de condiciones</th>
                <th style="font-weight: bold;" class="custom-header">Fotos</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-condition let-expanded="expanded">
              <tr class="text-sm">
                <td>
                  <p-button
                    type="button"
                    pRipple
                    [pRowToggler]="condition"
                    [text]="true"
                    [rounded]="true"
                    [plain]="true"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                  />
                </td>

                <td>{{ condition.name }}</td>
                <td>{{ condition.cleaning_data.length }}</td>

                <td>
                  <button
                    pButton
                    type="button"
                    icon="pi pi-image"
                    [rounded]="true"
                    (click)="openPhotoModalCondition(condition)"
                  >
                  </button>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-condition>
              <tr>
                <td colspan="4">
                  <div class="p-3">
                    <p-table
                      [value]="condition.cleaning_data"
                      dataKey="cleaning"
                      styleClass="p-datatable-sm p-datatable-striped"
                    >
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="font-weight: bold;">Tipo de limpieza</th>
                          <th style="font-weight: bold;">Áreas</th>
                          <th style="font-weight: bold;">Acción correctiva</th>
                          <th style="font-weight: bold;">Acción preventiva</th>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="body" let-detail>
                        <tr class="text-sm">
                          <td>{{ detail.cleaning }}</td>
                          <td>{{ detail.nesting_area.join(', ') }}</td>
                          <td>{{ detail.action_corrective ?? '' }}</td>
                          <td>{{ detail.preventive_action ?? '' }}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <p-dialog [(visible)]="isConditionPhotoModalVisible" [modal]="true" [header]="'Fotos de Condición'" [style]="{width: '50vw'}">
            <ng-template pTemplate="content">
              <p-carousel [value]="selectedCondition?.photos" [numVisible]="1" [numScroll]="1">
                <ng-template pTemplate="item" let-photo>
                  <div class="photo-container">
                    <img
                      [src]="photo.photo"
                      alt="Foto de condición"
                      style="width: 100%; border-radius: 5px;"
                    >

                    <p>
                      <strong>Área: </strong>
                      {{ photo?.nesting_area }}
                    </p>

                    <p>
                      <strong>Comentarios: </strong>
                      {{ photo?.comments }}
                    </p>
                  </div>
                </ng-template>
              </p-carousel>
            </ng-template>
          </p-dialog>
        }

        <!-- @if (event.stations.length > 0) { -->
        @if ([event.stations].length > 0) {
          <div class="table-title">
            <h3>Monitoreo de estaciones</h3>
          </div>

          <p-table
            [value]="[event.stations]"
            dataKey="id"
            [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            styleClass="p-datatable-sm p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="font-weight: bold;" class="custom-header">Nombre de la estación</th>
                <th style="font-weight: bold;" class="custom-header">Condiciones de la estación</th>
                <th style="font-weight: bold;" class="custom-header">Actividad en la estación</th>
                <th style="font-weight: bold;" class="custom-header">Tipo de actividad</th>
                <th style="font-weight: bold;" class="custom-header">Plagas</th>
                <th style="font-weight: bold;" class="custom-header">Fotos</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-station>
              <tr class="text-sm">
                <td>{{ station.station }}</td>
                <td>{{ station.station_condition?.join(', ') }}</td>
                <td>{{ station.station_activity?.join(', ') }}</td>
                <td>{{ station.stationTypeActivity?.join(', ') }}</td>

                <td>
                  @for (plague of station.plague_counts; track $index) {
                    <span>{{ plague.plague }}: </span> {{ plague.count_plague }}

                    @if ($index < station.plague_counts.length - 1) {
                      <br>
                    }
                  }
                </td>

                <td>
                  <button
                    pButton
                    type="button"
                    icon="pi pi-image"
                    [rounded]="true"
                    (click)="openPhotoModalStation(station)"
                  >
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <p-dialog [(visible)]="isStationPhotoModalVisible" [modal]="true" [header]="'Fotos del monitoreo de estaciones'" [style]="{width: '50vw'}">
            <ng-template pTemplate="content">
              <p-carousel [value]="selectedStation?.photos" [numVisible]="1" [numScroll]="1">
                <ng-template pTemplate="item" let-photo>
                  <div class="photo-container">
                    <img
                      [src]="photo.photo"
                      alt="Foto de control de plagas"
                      style="width: 100%; border-radius: 5px;"
                    >

                    <p>
                      <strong>Área: </strong>
                      {{ photo?.nesting_area }}
                    </p>

                    <p>
                      <strong>Comentarios: </strong>
                      {{ photo?.comments }}
                    </p>
                  </div>
                </ng-template>
              </p-carousel>
            </ng-template>
          </p-dialog>
        }

        @if (event.control.length > 0) {
          <div class="table-title">
            <h3>Control de plagas</h3>
          </div>

          <p-table
            [value]="event.control"
            dataKey="id"
            [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            [expandedRowKeys]="expandedControlsRows"
            (onRowExpand)="onRowExpandControl($event)"
            (onRowCollapse)="onRowCollapseControl($event)"
            styleClass="p-datatable-sm p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 3rem" class="custom-header"></th>
                <th style="font-weight: bold;" class="custom-header">Nombre</th>
                <th style="font-weight: bold;" class="custom-header">Número de productos</th>
                <th style="font-weight: bold;" class="custom-header">Fotos</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-control let-expanded="expanded">
              <tr class="text-sm">
                <td>
                  <p-button
                    type="button"
                    pRipple
                    [pRowToggler]="control"
                    [text]="true"
                    [rounded]="true"
                    [plain]="true"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                  />
                </td>

                <td>{{ control.name }}</td>
                <td>{{ control.products.length }}</td>

                <td>
                  <button
                    pButton
                    type="button"
                    icon="pi pi-image"
                    [rounded]="true"
                    (click)="openPhotoModalControl(control)"
                  >
                  </button>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-control>
              <tr>
                <td colspan="4">
                  <div class="p-3">
                    <p-table
                      [value]="control.products"
                      dataKey="batch"
                      styleClass="p-datatable-sm p-datatable-striped"
                    >
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="font-weight: bold;">Producto</th>
                          <th style="font-weight: bold;">Cantidad</th>
                          <th style="font-weight: bold;">Método de aplicación</th>
                          <th style="font-weight: bold;">Lote</th>
                          <th style="font-weight: bold;">Áreas</th>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="body" let-detail>
                        <tr class="text-sm">
                          <td>{{ detail.product }}</td>
                          <td>{{ detail.quantity }}</td>
                          <td>{{ detail.application_method }}</td>
                          <td>{{ detail.batch }}</td>
                          <td>{{ detail.nesting_area.join(', ') }}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <p-dialog [(visible)]="isControlPhotoModalVisible" [modal]="true" [header]="'Fotos del control de plagas'" [style]="{width: '50vw'}">
            <ng-template pTemplate="content">
              <p-carousel [value]="selectedControl?.photos" [numVisible]="1" [numScroll]="1">
                <ng-template pTemplate="item" let-photo>
                  <div class="photo-container">
                    <img
                      [src]="photo.photo"
                      alt="Foto de control de plagas"
                      style="width: 100%; border-radius: 5px;"
                    >

                    <p>
                      <strong>Área: </strong>
                      {{ photo?.nesting_area }}
                    </p>

                    <p>
                      <strong>Comentarios: </strong>
                      {{ photo?.comments }}
                    </p>
                  </div>
                </ng-template>
              </p-carousel>
            </ng-template>
          </p-dialog>
        }
      </section>
    } @else {
      <div class="loader-container">
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
      </div>
    }
  </div>
</app-layout>
