import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CatalogsService } from '../../services/catalogs/catalogs.service';

@Component({
  selector: 'app-status-filter',
  standalone: true,
  imports: [
    AutoCompleteModule
  ],
  templateUrl: './status-filter.component.html',
  styleUrl: './status-filter.component.scss'
})
export class StatusFilterComponent implements OnInit {
  private _catalogsService = inject(CatalogsService);

  @Output() onSelectStatus = new EventEmitter<string>();

  public statuses: any[] = [];
  public filteredStatuses: any[] = [];

  ngOnInit(): void {
    this.getStatuses();
  }

  getStatuses(): void {
    this._catalogsService.getStatuses('EV').subscribe((response: any[]) => {
      this.statuses = response;
      this.filteredStatuses = response;
    });
  }

  filterStatuses(event: any) {
    this.filteredStatuses = this.statuses.filter((status: any) => {
      return status.name.toLowerCase().includes(event.query.toLowerCase());
    });
  }

  handleSelectStatus(event: any) {
    this.onSelectStatus.emit(event.value);
  }
}
