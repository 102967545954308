<app-layout>
  <div class="home-element">
    <h2>
      Inspecciones
    </h2>

    <section class="table-container">
      <p-table
        #dt2
        [value]="services"
        dataKey="id"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loadingServices"
        [paginator]="true"
        styleClass="p-datatable-striped"
        [globalFilterFields]="['folio', 'event', 'employee', 'customer_name', 'customer_address', 'no_of_inspections']"
        [tableStyle]="{ 'min-width': '125rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width:10%">Folio</th>
            <th style="width:18%">Servicio</th>
            <th style="width:18%">Fecha / Hora de servicio</th>
            <th style="width:18%">Técnico responsable</th>
            <th style="width:18%">Cliente</th>
            <th style="width:18%">Dirección</th>
          </tr>

          <tr>
            <th>
              <p-columnFilter
                type="text"
                field="folio"
                placeholder="Buscar por folio"
                ariaLabel="Buscar por folio"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="event"
                placeholder="Buscar por servicio"
                ariaLabel="Buscar por servicio"
                matchMode="contains"
              />
            </th>

            <th></th>

            <th>
              <p-columnFilter
                type="text"
                field="employee"
                placeholder="Buscar por técnico"
                ariaLabel="Buscar por técnico"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="customer_name"
                placeholder="Buscar por cliente"
                ariaLabel="Buscar por cliente"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="customer_address"
                placeholder="Buscar por dirección"
                ariaLabel="Buscar por dirección"
                matchMode="contains"
              />
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-service>
          <tr>
            <td>
              <span
                class="folio"
                (click)="handleOpenModal(service)"
              >
                {{ service.folio }}
              </span>
            </td>

            <td>
              {{ service.event }}
            </td>

            <td>
              {{ service.date | date: 'dd/MM/yyyy' }}
              <br />
              {{ service.hour }}
            </td>

            <td>
              {{ service.employee }}
            </td>

            <td>
              {{ service.customer_name }}
            </td>

            <td>
              {{ service.customer_address }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">
              <p class="emptymessage">No se encontraron inspecciones</p>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </section>

    <p-dialog
      [modal]="true"
      [(visible)]="isModalVisible"
      [style]="{ width: '80%' }"
    >
      <ng-template pTemplate="header">
        <h3>
          Detalle de la Inspección
        </h3>
      </ng-template>

      @if (activeService) {
        <div class="modal-header">
          <article>
            <p>
              <span class="service-data">Folio del servicio: </span>
              {{ activeService.folio }}
            </p>

            <p>
              <span class="service-data">Técnico aplicador: </span>
              {{ activeService.employee }}
            </p>
          </article>

          <article>
            <p>
              <span class="service-data">Fecha: </span>
              {{ activeService.date | date: 'dd/MM/yyyy' }}
            </p>

            <p>
              <span class="service-data">Hora: </span>
              {{ activeService.hour }}
            </p>
          </article>
        </div>
      }

      <div>
        <p-table
          #dt2
          [value]="inspections"
          dataKey="id"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loadingInspections"
          [paginator]="true"
          styleClass="p-datatable-striped"
          [globalFilterFields]="['zone', 'nesting_area', 'plague', 'plagueCategory', 'infestationDegree', 'evidence_type', 'life_cycle_plague']"
          [tableStyle]="{ 'min-width': '150rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <!-- <th style="width:12.5%">Zona</th> -->
              <th style="width:10%">Área</th>
              <th style="width:15%">Plaga</th>
              <th style="width:15%">Categoría de plaga</th>
              <th style="width:15%">Grado de infestación</th>
              <th style="width:15%">Tipo de evidencia</th>
              <th style="width:15%">Ciclo de vida de la plaga</th>
              <th style="width:15%">Fotos</th>
            </tr>

            <tr>
              <!-- <th>
                <p-columnFilter
                  type="text"
                  field="zone"
                  placeholder="Buscar por zona"
                  ariaLabel="Buscar por zona"
                  matchMode="contains"
                />
              </th> -->

              <th>
                <p-columnFilter
                  type="text"
                  field="nesting_area"
                  placeholder="Buscar por área"
                  ariaLabel="Buscar por área"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="plague"
                  placeholder="Buscar por plaga"
                  ariaLabel="Buscar por plaga"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="plagueCategory"
                  placeholder="Buscar por categoría de plaga"
                  ariaLabel="Buscar por categoría de plaga"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="infestationDegree"
                  placeholder="Buscar por grado de infestación"
                  ariaLabel="Buscar por grado de infestación"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="evidence_type"
                  placeholder="Buscar por tipo de evidencia"
                  ariaLabel="Buscar por tipo de evidencia"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="life_cycle_plague"
                  placeholder="Buscar por ciclo de vida de la plaga"
                  ariaLabel="Buscar por ciclo de vida de la plaga"
                  matchMode="contains"
                />
              </th>

              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-inspection>
            <tr>
              <!-- <td>
                @if (inspection.zone) {
                  {{ inspection.zone }}
                } @else {
                  -
                }
              </td> -->

              <td>
                {{ inspection.nesting_area }}
              </td>

              <td>
                {{ inspection.plague }}
              </td>

              <td>
                {{ inspection.plagueCategory }}
              </td>

              <td>
                {{ inspection.infestationDegree }}
              </td>

              <td>
                {{ inspection.evidence_type }}
              </td>

              <td>
                {{ inspection.life_cycle_plague }}
              </td>

              <td class="photos-container">
                @if (inspection.photos.length > 0) {
                  @for (photo of inspection.photos; track $index) {
                    <img
                      [src]="photo"
                      alt="Foto de inspección"
                      class="photo"
                      width="100"
                    />
                  }
                } @else {
                  No hay fotos
                }
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="9">
                <p class="emptymessage">No se encontraron inspecciones</p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-dialog>
  </div>
</app-layout>
