import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment.prod';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);
  private url = `${environment.apiUrl}/administrative/events/`;

  constructor() { }

  getEvents(filters: any): Observable<any> {
    const token = this._authService.getToken()!;
    const session = this._sessionService.getSession();
    const customerId = session!.customer.id;

    let eventsUrl = `${this.url}?customer=${customerId}`;

    if (filters.page) {
      eventsUrl += `&page=${filters.page}`;
    }

    if (filters.page_size) {
      eventsUrl += `&page_size=${filters.page_size}`;
    }

    if (filters.search) {
      eventsUrl += `&search=${filters.search}`;
    }

    if (filters.initial_date) {
      eventsUrl += `&initial_date=${filters.initial_date}`;
    }

    if (filters.final_date) {
      eventsUrl += `&final_date=${filters.final_date}`;
    }

    if (filters.status__name) {
      eventsUrl += `&status__name=${filters.status__name}`;
    }

    return this._http.get<any>(eventsUrl, { headers: { Authorization: `Bearer ${token}` } });
  }

  // getEvents(): Observable<Event[]> {}

  // getEventById(id: string): Observable<Event> {}

  // createEvent(event: Event): Observable<Event> {}

  // updateEvent(event: Event): Observable<Event> {}

  // deleteEvent(event: Event): Observable<void> {}
}
