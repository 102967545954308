import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { MipDetailsService } from '../../services/mip/mip-details.service';

@Component({
  selector: 'app-event-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    DividerModule,
    TableModule,
    ButtonModule,
    DialogModule,
    CarouselModule
  ],
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.scss'
})
export class EventDetailsComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _mipDetailsService = inject(MipDetailsService);

  public eventId: string | null = null;
  public event: any | null = null;
  public expandedInspectionsRows: any = {};
  public isInspectionPhotoModalVisible: boolean = false;
  public selectedInspection: any | null = null;
  public expandedConditionsRows: any = {};
  public isConditionPhotoModalVisible: boolean = false;
  public selectedCondition: any | null = null;
  public isStationPhotoModalVisible: boolean = false;
  public selectedStation: any | null = null;
  public expandedControlsRows: any = {};
  public isControlPhotoModalVisible: boolean = false;
  public selectedControl: any | null = null;

  ngOnInit(): void {
    this.eventId = this._route.snapshot.paramMap.get('id');

    if (this.eventId) {
      this.fetchEventData(this.eventId);
    }
  }

  fetchEventData(eventId: string): void {
    this._mipDetailsService.getEventDetails(eventId).subscribe({
      next: ({ data: response }: any) => {
        this.event = response;

        forkJoin({
          customer: this._mipDetailsService
            .getEventCustomer(response.event.customer_id)
            .pipe(
              catchError(() => of(null))
            ),
          eventType: this._mipDetailsService
            .getEventType(response.event.event_type_id)
            .pipe(
              catchError(() => of(null))
            ),
          serviceType: this._mipDetailsService
            .getEventServiceType(response.event.service_type_id)
            .pipe(
              catchError(() => of(null))
            )
        }).subscribe({
          next: ({ customer, eventType, serviceType }) => {
            this.event.event.customer = customer;
            this.event.event.eventType = eventType;
            this.event.event.serviceType = serviceType;
          },
          error: (error) => {
            console.error('Error al cargar datos adicionales del evento:', error);
          }
        });
      },
      error: (error: any) => {
        console.error('Error al cargar los datos del evento:', error);
      }
    });
  }

  onRowExpandInspection(event: any) {
    this.expandedInspectionsRows[event.data.id] = true;
  }

  onRowCollapseInspection(event: any) {
    delete this.expandedInspectionsRows[event.data.id];
  }

  openPhotoModalInspection(inspection: any): void {
    this.selectedInspection = inspection;
    this.isInspectionPhotoModalVisible = true;
  }

  onRowExpandCondition(event: any) {
    this.expandedConditionsRows[event.data.id] = true;
  }

  onRowCollapseCondition(event: any) {
    delete this.expandedConditionsRows[event.data.id];
  }

  openPhotoModalCondition(condition: any): void {
    this.selectedCondition = condition;
    this.isConditionPhotoModalVisible = true;
  }

  openPhotoModalStation(station: any): void {
    this.selectedStation = station;
    this.isStationPhotoModalVisible = true;
  }

  onRowExpandControl(event: any) {
    this.expandedControlsRows[event.data.id] = true;
  }

  onRowCollapseControl(event: any) {
    delete this.expandedControlsRows[event.data.id];
  }

  openPhotoModalControl(control: any): void {
    this.selectedControl = control;
    this.isControlPhotoModalVisible = true;
  }
}
