import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { catchError, forkJoin, map, of, switchMap } from 'rxjs';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { InspectionResponse } from '../../models/areas.model';
import { AreasService } from '../../services/areas/areas.service';

interface Inspection {
  id: string;
  plague: string;
  plagueCategory?: string;
  infestationDegree: string;
  evidence_type: string;
  life_cycle_plague: string;
  nesting_area: string;
  zone_id?: string;
  zone?: string;
  photos: string[];
}

interface Service {
  id: string;
  folio: string;
  event: string;
  date: string;
  hour: string;
  employee: string;
  customer_name: string;
  customer_address: string;
}

@Component({
  selector: 'app-inspections',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    DropdownModule,
    LayoutComponent,
    TableModule,
    ButtonModule
  ],
  templateUrl: './inspections.component.html',
  styleUrl: './inspections.component.scss'
})
export class InspectionsComponent {
  private _areasService = inject(AreasService);

  public loadingServices = false;
  public loadingInspections = false;
  public isModalVisible = false;
  public isPhotoModalVisible = false;

  public activeService: Service | null = null;
  public services: Service[] = [];
  public inspections: Inspection[] = [];
  public selectedPhotos: string[] = [];

  public totalRecords: number = 0;
  public currentPage: number = 1;
  public rows: number = 10;
  public firstPage: number = 0;

  ngOnInit(): void {
    this.loadInspections(/* this.currentPage, this.rows */);
  }

  loadInspections(/* page: number, pageSize: number */): void {
    this.loadingServices = true;

    this._areasService.getInspectionsForms().pipe(
      switchMap((data: InspectionResponse[]) => {
        const uniqueEvents = data.reduce((acc: any[], item: any) => {
          if (!acc.find((event: any) => event.event === item.event)) {
            acc.push(item);
          }

          return acc;
        }, []);

        const requests = uniqueEvents.map((item) =>
          forkJoin({
            eventData: this._areasService
              .getEventData(item.event)
              .pipe(
                catchError(() => of(null))
              )
          }).pipe(
            map((result) => ({
              ...item,
              ...result,
            }))
          )
        );

        return forkJoin(requests);
      })
    ).subscribe({
      next: (results: any[]) => {
        this.services = results
          .filter(result => result.eventData !== null)
          .flatMap((result) => {
            return {
              id: result.eventData.data.id,
              folio: result.eventData.data.folio,
              event: result.eventData.data.title,
              date: result.eventData.data.initial_date,
              hour: result.eventData.data.initial_hour,
              employee: result.eventData.data.employee.map((employee: any) => employee.name).join(', '),
              customer_name: result.eventData.data.customer.name,
              customer_address: result.eventData.data.customer.address,
            };
          });

        this.loadingServices = false;
      },
      error: (err) => {
        console.error('Error al cargar los datos:', err);
        this.loadingServices = false;
      }
    });
  }

  handleOpenModal(service: Service): void {
    this.activeService = service;
    this.loadingInspections = true;

    this._areasService.getInspectionsFormsByEvent(service.id).pipe(
      switchMap((data: InspectionResponse[]) => {
        const uniqueEvents = data.reduce((acc: any[], item: any) => {
          if (!acc.find((event: any) => event.event === item.event)) {
            acc.push(item);
          }

          return acc;
        }, []);

        const requests = uniqueEvents.map((item) =>
          forkJoin({
            inspectionData: this._areasService.getInspectionsData(item.id),
            inspectionPhotos: this._areasService.getInspectionPhotos(item.id),
          }).pipe(
            map((result) => ({
              ...item,
              ...result,
            }))
          )
        );

        return forkJoin(requests);
      })
    ).subscribe({
      next: (results: any[]) => {
        const result = results[0];

        this.inspections = result.inspectionData.map((inspection: any) => ({
          id: inspection.id,
          plague: inspection.plague.name,
          plagueCategory: inspection.plague.category.name,
          infestationDegree: inspection.infestation_degree.name,
          evidence_type: inspection.evidence_type.map((evidence: any) => evidence.name).join(', '),
          life_cycle_plague: inspection.life_cycle_plague.map((cycle: any) => cycle.name).join(', '),
          nesting_area: inspection.nesting_area.map((area: any) => area.name).join(', '),
          zone_id: inspection.nesting_area.length > 0 ? inspection.nesting_area[0].zone : null,
          photos: result.inspectionPhotos.map((photo: any) => photo.photo),
        }));

        this.isModalVisible = true;
        this.loadingInspections = false;
      },
      error: (err) => {
        console.error('Error al cargar los datos:', err);
        this.loadingInspections = false;
      }
    });
  }

  openPhotoModal(photos: string[]): void {
    this.selectedPhotos = photos;
    this.isPhotoModalVisible = true;
  }
}
