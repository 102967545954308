<app-layout>
  <div class="home-element">
    <h2>
      <i class="pi pi-lightbulb"></i> Inspecciones
    </h2>

    <section class="table-container">
      <p-table
        #dt2
        [value]="services"
        dataKey="id"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loadingServices"
        [paginator]="true"
        styleClass="p-datatable-sm"
        [globalFilterFields]="['folio', 'event', 'employee', 'customer_name', 'customer_address', 'no_of_inspections']"
        [tableStyle]="{ 'min-width': '125rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width:10%" pSortableColumn="folio" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Folio <p-sortIcon field="folio" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="event" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Servicio <p-sortIcon field="event" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="date" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Fecha / Hora de servicio <p-sortIcon field="date" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="employee" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Técnico responsable <p-sortIcon field="employee" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="customer_name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Cliente <p-sortIcon field="customer_name" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="customer_address" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Dirección <p-sortIcon field="customer_address" />
              </p>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-service>
          <tr class="text-sm">
            <td>
              <span
                class="folio"
                (click)="handleOpenModal(service)"
              >
                {{ service.folio }}
              </span>
            </td>

            <td>
              {{ service.event }}
            </td>

            <td>
              {{ service.date | date: 'dd/MM/yyyy' }}
              <br />
              {{ service.hour }}
            </td>

            <td>
              {{ service.employee }}
            </td>

            <td>
              {{ service.customer_name }}
            </td>

            <td>
              {{ service.customer_address }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">
              <p class="emptymessage">No se encontraron inspecciones</p>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </section>

    <p-dialog
      [modal]="true"
      [(visible)]="isModalVisible"
      [style]="{ width: '80%' }"
    >
      <ng-template pTemplate="header">
        <h3>
          Detalle de la Inspección
        </h3>
      </ng-template>

      @if (activeService) {
        <div class="modal-header">
          <article>
            <p>
              <span class="service-data">Folio del servicio: </span>
              {{ activeService.folio }}
            </p>
            <p>
              <span class="service-data">Técnico aplicador: </span>
              {{ activeService.employee }}
            </p>
          </article>
        </div>

        <div class="modal-body">
          <p-table [value]="inspections" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                <th>Plaga</th>
                <th>Grado de infestación</th>
                <th>Evidencia</th>
                <th>Fotos</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-inspection>
              <tr>
                <td>{{ inspection.plague }}</td>
                <td>{{ inspection.infestationDegree }}</td>
                <td>{{ inspection.evidence_type }}</td>

                <td>
                  @if (inspection.photos.length > 0) {
                    <p-button
                      type="button"
                      icon="pi pi-images"
                      [rounded]="true"
                      class="p-button-info"
                      (click)="openPhotoModal(inspection.photos)"
                    ></p-button>
                  } @else {
                    <span class="text-gray-500">
                      No hay fotos disponibles
                    </span>
                  }
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      }
    </p-dialog>

    <p-dialog [(visible)]="isPhotoModalVisible" [modal]="true" [style]="{ width: '60%' }">
      <ng-template pTemplate="header">
        <h3>Fotos de la Inspección</h3>
      </ng-template>

      <div class="photos-container">
        @if (selectedPhotos.length > 0) {
          @for (photo of selectedPhotos; track $index) {
            <div class="photo">
              <img [src]="photo" alt="Foto de inspección" class="photo-preview"/>
            </div>
          }
        } @else {
          <p>No hay fotos disponibles</p>
        }
      </div>
    </p-dialog>
  </div>
</app-layout>
