<app-layout>
  <div class="home-element">
    <h2>
      Áreas de inspección
    </h2>

    <section class="table-container">
      <p-table
        #dt2
        [value]="services"
        dataKey="id"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loadingServices"
        [paginator]="true"
        styleClass="p-datatable-striped"
        [globalFilterFields]="['folio', 'event', 'employee', 'customer_name', 'customer_address']"
        [tableStyle]="{ 'min-width': '125rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width:15%">Folio</th>
            <th style="width:15%">Servicio</th>
            <th style="width:15%">Fecha / Hora de servicio</th>
            <th style="width:15%">Técnico responsable</th>
            <th style="width:15%">Cliente</th>
            <th style="width:15%">Dirección</th>
            <th style="width:10%">Número de áreas</th>
          </tr>

          <tr>
            <th>
              <p-columnFilter
                type="text"
                field="folio"
                placeholder="Buscar por folio"
                ariaLabel="Buscar por folio"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="event"
                placeholder="Buscar por servicio"
                ariaLabel="Buscar por servicio"
                matchMode="contains"
              />
            </th>

            <th></th>

            <th>
              <p-columnFilter
                type="text"
                field="employee"
                placeholder="Buscar por técnico"
                ariaLabel="Buscar por técnico"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="customer_name"
                placeholder="Buscar por cliente"
                ariaLabel="Buscar por cliente"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="customer_address"
                placeholder="Buscar por dirección"
                ariaLabel="Buscar por dirección"
                matchMode="contains"
              />
            </th>

            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-service>
          <tr>
            <td>
              <span
                class="folio"
                (click)="handleOpenModal(service)"
              >
                {{ service.folio }}
              </span>
            </td>

            <td>
              {{ service.event }}
            </td>

            <td>
              {{ service.date | date: 'dd/MM/yyyy' }}
              <br />
              {{ service.hour }}
            </td>

            <td>
              {{ service.employee }}
            </td>

            <td>
              {{ service.customer_name }}
            </td>

            <td>
              {{ service.customer_address }}
            </td>

            <td>
              {{ service.no_of_areas }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">
              <p class="emptymessage">No se encontraron inspecciones</p>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </section>

    <p-dialog
      [modal]="true"
      [(visible)]="isModalVisible"
      [style]="{ width: '80%' }"
    >
      <ng-template pTemplate="header">
        <h3>
          Detalle de la Inspección
        </h3>
      </ng-template>

      @if (activeService) {
        <div class="modal-header">
          <article>
            <p>
              <span class="service-data">Folio del servicio: </span>
              {{ activeService.folio }}
            </p>

            <p>
              <span class="service-data">Técnico aplicador: </span>
              {{ activeService.employee }}
            </p>
          </article>

          <article>
            <p>
              <span class="service-data">Fecha: </span>
              {{ activeService.date | date: 'dd/MM/yyyy' }}
            </p>

            <p>
              <span class="service-data">Hora: </span>
              {{ activeService.hour }}
            </p>
          </article>
        </div>
      }

      <div>
        <p-table
          #dt2
          [value]="areas"
          dataKey="id"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loadingAreas"
          [paginator]="true"
          styleClass="p-datatable-striped"
          [globalFilterFields]="['station', 'station_activity', 'station_type_activity', 'station_condition', 'activity_in_station', 'employee_activity']"
          [tableStyle]="{ 'min-width': '150rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width:12.5%">Estación</th>
              <th style="width:12.5%">Actividad</th>
              <th style="width:12.5%">Tipo de actividad</th>
              <th style="width:12.5%">Condición</th>
              <th style="width:12.5%">Plagas</th>
              <th style="width:12.5%">Actividad en la estación</th>
              <th style="width:12.5%">Actividad del empleado</th>
              <th style="width:12.5%">Fotos</th>
            </tr>

            <tr>
              <th>
                <p-columnFilter
                  type="text"
                  field="station"
                  placeholder="Buscar por estación"
                  ariaLabel="Buscar por estación"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="station_activity"
                  placeholder="Buscar por actividad"
                  ariaLabel="Buscar por actividad"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="station_type_activity"
                  placeholder="Buscar por tipo de actividad"
                  ariaLabel="Buscar por tipo de actividad"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="station_condition"
                  placeholder="Buscar por condición"
                  ariaLabel="Buscar por condición"
                  matchMode="contains"
                />
              </th>

              <th></th>

              <th>
                <p-columnFilter
                  type="text"
                  field="activity_in_station"
                  placeholder="Buscar por actividad en la estación"
                  ariaLabel="Buscar por actividad en la estación"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="employee_activity"
                  placeholder="Buscar por actividad del empleado"
                  ariaLabel="Buscar por actividad del empleado"
                  matchMode="contains"
                />
              </th>

              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-station>
            <tr>
              <td>
                {{ station.station }}
              </td>

              <td>
                {{ station.station_activity }}
              </td>

              <td>
                {{ station.station_type_activity }}
              </td>

              <td>
                {{ station.station_condition }}
              </td>

              <td>
                @if (station.plagues.length > 0) {
                  @for (plague of station.plagues; track $index) {
                    {{ plague.plague }}: {{ plague.count }}
                    <br />
                  }
                } @else {
                  No hay plagas
                }
              </td>

              <td>
                {{ station.activity_in_station }}
              </td>

              <td>
                {{ station.employee_activity }}
              </td>

              <td class="photos-container">
                @if (station.photos.length > 0) {
                  @for (photo of station.photos; track $index) {
                    <img
                      [src]="photo"
                      alt="Foto de inspección"
                      class="photo"
                      width="100"
                    />
                  }
                } @else {
                  No hay fotos
                }
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="9">
                <p class="emptymessage">No se encontraron inspecciones</p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-dialog>
  </div>
</app-layout>
