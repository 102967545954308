<div class='demo-app home-element'>
  <div class="header-row">
    <h2>
      <i class="pi pi-calendar"></i> Mi Agenda
    </h2>

    <div class="header-buttons">
      @if (isForThisCustomer()) {
        <p-button (click)="handleDownload()" icon="pi pi-download" label="Descargar" class="button">
        </p-button>
      }

      <p-button (click)="handlePrintCalendar()" icon="pi pi-file-pdf" label="Imprimir" class="button">
      </p-button>
    </div>
  </div>

  <div class='demo-app-main calendar-container'>
    @if (calendarVisible) {
      <full-calendar [options]='calendarOptions' #fullCalendar>
        <ng-template #eventContent let-arg>
          <div class="custom-event-content">
            <span class="time-text">{{ arg.timeText }}</span> <br>
            <i>{{ arg.event.title }}</i>
          </div>
        </ng-template>
      </full-calendar>
    }
  </div>

  <p-dialog
    [modal]="true"
    [(visible)]="isModalVisible"
    [style]="{ width: '30rem' }"
  >
    <ng-template pTemplate="header">
      <div class="dialog-header">
        <i class="pi pi-file-pdf" style="font-size: 2rem"></i>

        @if (selectedEvent) {
          <span class="dialog-header-title">
            Documentos de {{ selectedEvent.title }}
          </span>
        } @else {
          <span class="dialog-header-title">
            Documentos
          </span>
        }
      </div>
    </ng-template>

    <div>
      <ul class="list-of-documents">
        @if (isModalLoading) {
          <li>
            <span class="no-documents">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </span>
          </li>
        } @else if (documents.length === 0) {
          <li>
            <span class="no-documents">
              No hay documentos
            </span>
          </li>
        }

        @for (document of documents; track document.url) {
          <li class="list-document-element">
            <button
              class="document-item"
              (click)="handleOpenDocument(document)"
            >
              <i class="pi pi-file-pdf" style="font-size: 1.5rem; color: #f43f5e;"></i>
              <span>{{ document.name }}</span>
            </button>

            <button
              class="document-download"
              (click)="handleDownloadDocument(document)"
            >
              <i class="pi pi-download" style="font-size: 1rem; color: #f43f5e;"></i>
            </button>
          </li>
        }
      </ul>
    </div>
  </p-dialog>

  <p-dialog
    [modal]="true"
    [(visible)]="iframeModalVisible"
    [style]="{ width: '80%' }"
  >
    <ng-template pTemplate="header">
      <div class="dialog-header">
        <i class="pi pi-file-pdf" style="font-size: 2rem"></i>

        @if (selectedDocument) {
          <span class="dialog-header-title">
            {{ selectedDocument.name }}
          </span>
        } @else if (isForCalendar) {
          <span class="dialog-header-title">
            Calendario
          </span>
        } @else {
          <span class="dialog-header-title">
            Documento
          </span>
        }
      </div>
    </ng-template>

    @if (isModalLoading) {
      <span class="no-documents">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </span>
    }

    @if (documentUrl) {
      <div>
        <iframe
          [src]="documentUrl | safeUrl"
          width="100%"
          height="500"
          frameborder="0"
        ></iframe>
      </div>
    }
  </p-dialog>
</div>
